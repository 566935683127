import * as views from "./view";

export default [
    {
        path: "/scenarios",
        exact: true,
        component: views.Scenarios,
        hasMenu: false
    },
    {
        path: "/scenarios/:scenarioId/editor",
        exact: true,
        component: views.ScenarioEditor,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/scenario_history",
        exact: true,
        component: views.ScenarioHistory,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/scenario_deploy",
        exact: true,
        component: views.ScenarioDeploy,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/bulk_test",
        exact: true,
        component: views.ScenarioBulkTest,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/templates",
        exact: true,
        component: views.SceneTemplateEditor,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations",
        exact: true,
        component: views.IntegraionMain,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/webchat",
        exact: true,
        component: views.WebChatIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/kakaotalk",
        exact: true,
        component: views.KakaoTalkIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/navertalk",
        exact: true,
        component: views.NaverTalkIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/facebook",
        exact: true,
        component: views.FacebookIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/instagram",
        exact: true,
        component: views.InstagramIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/teamup",
        exact: true,
        component: views.TeamUpIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/rcs",
        exact: true,
        component: views.RCSIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/line",
        exact: true,
        component: views.LineIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/azure",
        exact: true,
        component: views.AzureIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/slack",
        exact: true,
        component: views.SlackIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/jandi",
        exact: true,
        component: views.JandiIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/enomix",
        exact: true,
        component: views.EnomixIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/uipath",
        exact: true,
        component: views.UiPathIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/zendesk",
        exact: true,
        component: views.ZendeskIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/swingchat_message",
        exact: true,
        component: views.SwingChatMessageIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/integrations/livechat",
        exact: true,
        component: views.LiveChatIntegrationView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/conversations",
        exact: true,
        component: views.MessageHistory,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/settings",
        exact: true,
        component: views.Settings,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/flows",
        exact: true,
        component: views.ScenarioFlow,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/analytics",
        exact: true,
        component: views.AnalyticsView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/assistant/condition",
        exact: true,
        component: views.ConditionAssistantView,
        hasMenu: true
    },
    {
        path: "/scenarios/:scenarioId/members",
        exact: true,
        component: views.Members,
        hasMenu: true
    },
    {
        component: views.NotFound
    }
];
